import Vue from "vue"

const req = require.context('./content', false, /\.md$/)
req.keys().forEach((path) => {   
    let arr=path.split('/')
    let name=arr[arr.length-1].split('.md')[0]
    const component = req(path).default
    Vue.component(name, component)
  })
export default {
    diaryList: [{
        time:'2023-08-15 22:44',
        zh: {
            time: '2023年8月15日 星期二 22:44',
            title: '这十年的代码生涯',
            //other:"",
            subTitle:'我想这十年可以用三段我博客的话来描述吧:“ ”、“活着的意义就是寻找活着的意义”、“在我离开世界之前想多知道一点”',
            md:'ten-year-code-career'
        },
        en: {
            time: 'Tuesday, August 15, 2023 at 22:44',
            title: `Ten years of coding career`,
            subTitle:'I think this decade can be described in three paragraphs from my blog: " "、"The meaning of living is to find the meaning of living"、"I want to know a little more before I leave the world"',
            //other:"测试一下这个other好不好用",
            md:'ten-year-code-career'
        }
    },{
        time:'2024-01-13 00:11',
        zh: {
            time: '2024年01月13日 星期六 00:11',
            title: '如此打工三十年',
            subTitle:'观网易纪录片《如此打工三十年》后感',
            md:'working-for-thirty-years'
        },
        en: {
            time: 'starday, January 13, 2024 00:11',
            title: `Working for thirty years`,
            subTitle:'Thoughts after watching the NetEase documentary "Working Like This for Thirty Years".',
            md:'working-for-thirty-years(en)'
        }
    },{
        time:'2024-01-04 22:52',
        zh: {
            time: '2024年01月04日 星期四 22:52',
            title: '你我皆不是主角！',
            subTitle:'从小到大一直有个观念，认为自己就是这个世界的主角。',
            md:'we-are-not-protagonists'
        },
        en: {
            time: 'Thursday, January 4, 2024 22:52',
            title: `We aren't protagonist`,
            subTitle:'Growing up, I thought I was the protagonist of this world.',
            md:'we-are-not-protagonists(en)'
        }
    },{
        time:'2024-01-03 16:19',
        zh: {
            time: '2024年01月03日 星期三 16:19',
            title: '理想还是现实，这是个问题！',
            subTitle:'我曾说过古往今来理想主义者的结局往往很凄惨，而现实主义者通常幸福美满。',
            md:'Ideal-or-reality'
        },
        en: {
            time: 'Wednesday, January 3, 2024 16:19',
            title: `Ideal or reality, that is the question!`,
            subTitle:'I have said that idealists throughout the ages have often had miserable endings, while realists have usually had happy endings.',
            md:'Ideal-or-reality(en)'
        }
    },{
        time:'2023-12-11 21:32',
        zh: {
            time: '2023年12月11日 星期一 21:32',
            title: '战胜苦难的两种方式',
            subTitle:'苦难不值得歌颂，但，我们无法消除苦难！',
            md:'two-ways-overcome-suffering'
        },
        en: {
            time: 'Monday, December 11, 2023 21:32',
            title: `Two ways to overcome suffering`,
            subTitle:'Difficulties are not worthy of praise, but we cannot eliminate them!',
            md:'two-ways-overcome-suffering(en)'
        }
    },{
        time:'2023-12-11 20:33',
        zh: {
            time: '2023年12月11日 星期一 20:33',
            title: '铁生给抑郁者们的一段话',
            subTitle:'曾几何时我被抑郁困扰，一直都在寻找治疗抑郁的良药，但总是无功而返，久而久之我便认为抑郁只有自救，可直到我读了铁生的书。',
            md:'message-from-tiesheng-to-depressed'
        },
        en: {
            time: 'Monday, December 11, 2023 20:33',
            title: `Message from Tiesheng to depressed`,
            subTitle:"There was a time when I was troubled by depression, constantly searching for a remedy to treat it, but my efforts were always in vain. Over time, I came to believe that overcoming depression was a journey of self-rescue. It wasn't until I read Tiesheng's book that I found solace.",
            md:'message-from-tiesheng-to-depressed(en)'
        }
    },{
        time:'2023-11-29 22:24',
        zh: {
            time: '2023年11月29日 星期三 22:24',
            title: '何为教育？',
            subTitle:'写在看丁老师视频后感',
            md:'what-is-education'
        },
        en: {
            time: 'Wednesday, November 29, 2023 22:24',
            title: `What is Education?`,
            subTitle:'Written after watching Teacher Ding‘s video',
            md:'what-is-education(en)'
        }
    },{
        time:'2023-11-12 13:44',
        zh: {
            time: '2023年11月12日 星期天 13:44',
            title: '我们的技术到底在为谁服务？',
            other:"写在看《奥本海默》后感",
            subTitle:'写在看《奥本海默》后感',
            md:'who-does-our-technology-serve'
        },
        en: {
            time: 'Sunday, November 12, 2023 13:44',
            title: `Who does our technology serve?`,
            subTitle:'Written after watching 《Oppenheimer》',
            other:"Written after watching 《Oppenheimer》",
            md:'who-does-our-technology-serve(en)'
        }
    },{
        time:'2023-11-02 21:03',
        zh: {
            time: '2023年11月02日 星期四 21:03',
            title: '我有一个梦想',
            //other:"",
            subTitle:'我有一个梦想——我想开一间图书馆。',
            md:'i-have-a-dream'
        },
        en: {
            time: 'Thursday, November 02, 2023 21:03',
            title: `I have a dream`,
            subTitle:'I have a dream - I want to open a library.',
            //other:"测试一下这个other好不好用",
            md:'i-have-a-dream(en)'
        }
    }]
}