export default {
    technologyList: [{
        id: 'HTTPExplanation',
        link: 'https://wangjunliang.com/HTTP-Explanation/',
        zh: {
            title: 'HTTP完全注解',
            subTitle: '一份全面、集中、准确的HTTP完全注解',
            btnText:'马上学习 →',
            img: require('@/assets/img/HTTP.png'),
        },
        en: {
            title: 'HTTP Explanation',
            subTitle: 'A comprehensive、 focused and accurate HTTP Explanation',
            btnText:'Learn more →',
            img: require('@/assets/img/HTTP.png'),
        }
    },{
        id: 'FrontEndEngineeringGuide',
        link: 'https://wangjunliang.com/FrontEnd-Engineering-Guide/',
        zh: {
            title: '前端工程化指南',
            subTitle: '一份全面、集中、准确的前端工程化指南',
            btnText:'马上学习 →',
            img: require('@/assets/img/frontend-engineering-guide.png'),
        },
        en: {
            title: 'FrontEnd Engineering Guide',
            subTitle: 'A comprehensive、 focused and accurate FrontEnd Engineering Guide',
            btnText:'Learn more →',
            img: require('@/assets/img/frontend-engineering-guide.png'),
        }
    },{
        id:'jsAndLeetcode',
        link: 'https://wangjunliang.com/JS-Algorithm/',
        zh:{
          title:'js每日一算法',
          subTitle:'每天学习一个JS算法',
          btnText:'马上学习 →',
          img:require('@/assets/img/js-algorithm.png'),
        },
        en:{ 
          title:'JS Algorithm',
          subTitle:'Learn a JS algorithm every day',
          btnText:'Learn more →',
          img:require('@/assets/img/js-algorithm.png'),
        }
      },{
        id: 'HTMLGuide',
        link: 'https://wangjunliang.com/HTML-Guide/',
        zh: {
            title: 'HTML指南',
            subTitle: '一份全面且准确的HTML指南',
            btnText:'马上学习 →',
            img: require('@/assets/img/HTMLGuidezh.png'),
        },
        en: {
            title: 'HTML Guide',
            subTitle: 'A comprehensive and accurate HTML Guide',
            btnText:'Learn more →',
            img: require('@/assets/img/HTMLGuideen.png'),
        }
    }]
}