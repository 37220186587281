import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'



//ElementUI
import {Select,DatePicker,Input,Dropdown,DropdownMenu,DropdownItem,Option,Tag,Button } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Select);
Vue.use(Option);
Vue.use(Tag);
Vue.use(DatePicker);
Vue.use(Input);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Dropdown);
Vue.use(Button);


//国际化
import i18n from './language/i18n'
//自定义配置
import config from './config/index'
Vue.prototype.config = config;
// markdown样式
import 'github-markdown-css'
// 代码高亮
import 'highlight.js/styles/github.css'


//引入全局css
import '@/assets/css/index.js'

import './registerServiceWorker'

Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
 