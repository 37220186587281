<template>
  <div id="app" :class="$store.state.model">
    <transition name="puffIn" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: "app",
  mounted() {
    let item = localStorage.getItem("language");
    if (item != undefined) {
      this.$i18n.locale = item;
    } else {
      if (navigator.language.includes("zh")) this.$i18n.locale = "zh";
      else this.$i18n.locale = "en";
    }
  },
};
</script>

<style>
</style>
