/*
 * @Description: 项目列表配置
 * @Author: JunLiangWang
 * @Date: 2023-03-11 17:09:32
 * @LastEditors: JunLiangWang
 * @LastEditTime: 2024-03-05 19:32:42
 */
export default {
  projectList: [{
    id: 'bootsjs',
    img: require('@/assets/img/boots-js.png'),
    zh: {
      time: '2024年2月28日',
      title: 'BootsJS',
      subTitle: 'BootsJS 是一个致力于扩展原生 JavaScript 功能的库，旨在解决 JavaScript 原生不支持的常见数据结构、方法和常用算法。',
      tag: [{
        title: 'js',
        type: 'success',
        value: 'js'
      }, {
        title: 'libray',
        type: 'warning',
        value: 'libray'
      }],
      button: [{
        title: 'Github',
        color: 'white',
        background: '#606266',
        icon: 'iconfont icongithub',
        link: 'https://github.com/JunLiangWangX/BootsJS/tree/main'
      }]
    },
    en: {
      time: 'February 28, 2024',
      title: 'BootsJS',
      subTitle: 'BootsJS is a library dedicated to extending the capabilities of native JavaScript, aiming to address common data structures, methods, and frequently used algorithms that are not natively supported in JavaScript.',
      tag: [{
        title: 'js',
        type: 'success',
        value: 'js'
      }, {
        title: 'libray',
        type: 'warning',
        value: 'libray'
      }],
      button: [{
        title: 'Github',
        color: 'white',
        background: '#606266',
        icon: 'iconfont icongithub',
        link: 'https://github.com/JunLiangWangX/BootsJS/tree/main'
      }]
    }
  }, /*{
    id: 'jsAndLeetcode',
    img: require('@/assets/img/jsAndLeetcode.svg'),
    zh: {
      time: '2023年3月9日',
      title: 'leetcode题解',
      subTitle: '使用js实现leetcode各种算法题',
      tag: [{
        title: '算法',
        type: 'success',
        value: 'algorithm'
      }, {
        title: 'js',
        type: 'warning',
        value: 'js'
      }],
      button: [{
        title: 'Github',
        color: 'white',
        background: '#606266',
        icon: 'iconfont icongithub',
        link: 'https://github.com/JunLiangWangX/js-algorithm'
      }]
    },
    en: {
      time: 'March 9,2023',
      title: 'leetcode solution',
      subTitle: 'Use js to realize leetcode various algorithm problems',
      tag: [{
        title: 'algorithm',
        type: 'success',
        value: 'algorithm'
      }, {
        title: 'js',
        type: 'warning',
        value: 'js'
      }],
      button: [{
        title: 'Github',
        color: 'white',
        background: '#606266',
        icon: 'iconfont icongithub',
        link: 'https://github.com/JunLiangWangX/js-algorithm'
      }]
    }
  },*/ {
    id: 'madManDiary',
    img: require('@/assets/img/madManDiary.svg'),
    zh: {
      time: '2023年3月1日',
      title: '狂人日记',
      subTitle: '我亦赴某地候补为官或是被青面獠牙的世人吃掉？',
      tag: [{
        title: '文学',
        type: 'danger',
        value: 'literature'
      }],
      button: [{
        title: '国内访问',
        color: 'white',
        background: '#fc490b',
        icon: 'iconfont iconfangwen',
        link: 'https://wangjunliang.com/Madman-Diary/'
      }, {
        title: '国外访问',
        color: 'white',
        background: '#5f5f5f',
        icon: 'iconfont iconwangluo',
        link: 'https://junliangwangx.github.io/Madman-Diary/'
      }]
    },
    en: {
      time: 'March 1,2023',
      title: 'Madman Diary',
      subTitle: 'Am I going to be a candidate for an official somewhere, or will I be eaten by the green-faced and fang-toothed people?',
      tag: [{
        title: 'literature',
        type: 'danger',
        value: 'literature'
      }],
      button: [{
        title: 'Domestic',
        color: 'white',
        background: '#fc490b',
        icon: 'iconfont iconfangwen',
        link: 'https://wangjunliang.com/Madman-Diary/'
      }, {
        title: 'Foreign',
        color: 'white',
        background: '#5f5f5f',
        icon: 'iconfont iconwangluo',
        link: 'https://junliangwangx.github.io/Madman-Diary/'
      }]
    }
  },]
}