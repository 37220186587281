import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    model: localStorage.getItem('model')?localStorage.getItem('model'):
    new Date().getHours()>=19 ?'dark':'light',//当前模式：light/dark
  },
  mutations: {
    changeModel(state) {
      state.model = (state.model=='light'?'dark':'light')
      localStorage.setItem('model',state.model)
    }
  },
  actions: {},
  modules: {}
})